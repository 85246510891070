
.report {
  h1 {
    @apply text-3xl font-bold;
  }
  h2 {
    @apply text-2xl font-bold;
  }
  h3 {
    @apply text-xl font-semibold;
  }
  h4 {
    @apply text-lg font-semibold;
  }
  h5 {
    @apply font-semibold;
  }
  ul {
    @apply list-disc list-inside 
  }
  ol {
    @apply list-decimal list-inside
  }
  li {
    @apply list-item 
  }
  a {
    @apply underline text-dro-lightblue
  }
  
  @apply text-dro-blue space-y-6
}
