.LanguageSelector {
  background-color: var(--color-dark);
  border: none;
  color: var(--color-background);
  padding: 16px 32px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: var(--color-shade);
  }
}
