/**
 * https://a11y-dialog.netlify.app/usage/styling/
 */

/**
 * 1. Make the dialog container, and its child overlay spread across
 *    the entire window.
 */
.dialog-container,
.dialog-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.dialog-container {
  z-index: 2;
  display: flex;
}

/**
  * 1. Make sure the dialog container and all its descendants are not
  *    visible and not focusable when it is hidden.
  */
.dialog-container[aria-hidden="true"] {
  display: none;
}

.dialog-overlay {
  background-color: rgba(43, 46, 56, 0.75);
}

.dialog-content {
  border-radius: 8px;
  width: calc(100% - 2rem);
  //padding: .8rem 3rem 0 1rem !important;
  height: 100%;
  margin: auto !important;
  z-index: 2;
  background-color: white;
  position: relative;
}

.dialog-title {
  margin-top: 0 !important;
}

.dialog-scrollable {
  overflow-y: auto;
  height: calc(100%);
  width: 100%;
  padding: 1rem 3rem 1rem 1rem !important;
}

.dialog-close {
  display: none;
  line-height: 0;
  z-index: 3;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: none;
  color: #000;
  font-size: 24px;

  &:hover,
  &:focus {
    background: none;
  }
}

.dialog-content--size {
  &-regular {
    max-width: 800px;
    max-height: 70vh;
  }

  &-full {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    border-radius: 0;
    background: var(--color-8);
  }

  &-small {
    max-width: 480px;
    max-height: 400px;
  }
}

.dialog-toolbar {
  position: absolute;
  top: 1rem;
  right: 3rem;
  z-index: 100;
}
