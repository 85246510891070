@use 'sass:list';
@import '../styles/dialog.scss';

@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 500;
  src: url(assets/fonts/NotoSerif-Medium.ttf);
}

@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/NotoSerif-Bold.ttf);
}

$old-color-1: rgb(66, 143, 3);
$old-color-2: #428f031f;
$old-color-3: #e7e7e7;
$old-color-4: rgb(142, 209, 252);
$old-color-5: #d1d5db;
$old-color-6: white;
$old-color-7: #333;
$old-color-8: #f3f4f6;
$old-color-9: #666;

$old-color-list: (
  $old-color-1 $old-color-2 $old-color-3 $old-color-4 $old-color-5 $old-color-6
    $old-color-7 $old-color-8 $old-color-9
);

$color-1: #316c00;
$color-2: #ffffff;
$color-3: #001f44;
$color-4: #5b5b5b;
$color-5: #dbdbdb;
$color-6: #316c00;

$color-list: ($color-1 $color-2 $color-3 $color-4 $color-5 $color-6);

@mixin make-colored-classes($class-name, $property, $color-list-var) {
  @for $i from 1 through length($color-list-var) {
    .#{$class-name}-#{$i} {
      #{$property}: list.nth($color-list-var, $i);
    }
    .hover\:#{$class-name}-#{$i} {
      &:hover {
        #{$property}: list.nth($color-list-var, $i);
      }
    }
  }
}
// layout

@mixin container-base {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@mixin button-base {
  cursor: pointer;
  text-decoration: unset;
  outline: unset;
  display: inline-block;
  font-weight: normal;
  border-radius: 13px;
  min-width: 100px;
  text-align: center;
  transition: all 0.125s ease-in;

  &:active {
    opacity: 0.7;
    transform: scale(97%);
  }
}

@mixin old-button-base {
  cursor: pointer;
  text-decoration: unset;
  outline: unset;
  display: inline-block;
  font-weight: normal;
  border-radius: 13px;
  padding: 13px;
  min-width: 100px;
  text-align: center;
  transition: all 0.125s ease-in;
  margin-right: 1rem;
  width: auto;

  &:active {
    transform: scale(95%);
  }

  &:focus-visible {
    box-shadow: 0 0 1px 2px $old-color-1;
    outline: unset;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

@mixin alert-base {
  color: #084298;
  background: #cfe2ff;
  border: 1px solid #b6d4fe;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 12px 1rem;
  border-radius: 8px;
  font-size: 18px;
}

// layout

.container-s {
  @include container-base;

  max-width: 360px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.container-m {
  @include container-base;
  max-width: 720px;
  padding-left: 20px;
  padding-right: 20px;

  // 760 => total of max-width + paddings
  @media screen and (min-width: 760px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.container-l {
  @include container-base;
  max-width: 1000px;
  padding-left: 20px;
  padding-right: 20px;

  // 1040 => total of max-width + paddings
  @media screen and (min-width: 1040px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.container-xl {
  @include container-base;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 1280px;
}

// coloring properties for old colors
@include make-colored-classes(bg-old, background, $old-color-list);
@include make-colored-classes(bg-old-color, background-color, $old-color-list);
@include make-colored-classes(old-color, color, $old-color-list);
@include make-colored-classes(old-border-color, border-color, $old-color-list);

// coloring properties for new colors

@include make-colored-classes(bg, background, $color-list);
@include make-colored-classes(bg-color, background-color, $color-list);
@include make-colored-classes(color, color, $color-list);
@include make-colored-classes(border-color, border-color, $color-list);

// buttons

.old-button-primary {
  @include old-button-base;
  background: $old-color-1;
  color: $old-color-6;
  box-shadow: 5px 5px 10px #a1a09c, -5px -5px 10px #ffffff;
  border: none;
  transition: all 0.125s ease-in;

  &:hover {
    border: none;
    background: $old-color-1;
    color: $old-color-6;
    box-shadow: inset 5px 5px 10px #265302, inset -5px -5px 10px #5ecb04;
  }
}

.old-button-secondary {
  @include old-button-base;
  background: $old-color-8;
  color: $old-color-7 !important;
  box-shadow: 5px 5px 10px #a5a4a2, -5px -5px 10px #ffffff;
  transition: all 0.125s ease-in;
  text-decoration: none !important;
  &:hover {
    background: $old-color-8;
    color: $old-color-7;
    box-shadow: inset 5px 5px 10px #8b8a88, inset -5px -5px 10px #ffffff;
  }
}

.old-button-chat {
  @include old-button-base;
  background: $old-color-1;
  color: $old-color-6;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 18px;
  line-height: 22px;
  &:hover,
  &:focus {
    border: none;
    background: $old-color-1;
    color: $old-color-6;
  }
}

.old-button-circular {
  width: 38px;
  height: 38px;
  display: inline-block;
  border-radius: 50%;
  background: $old-color-1;
  color: $old-color-6;
  border: none;

  &:hover,
  &:focus {
    border: none;
    background: $old-color-1;
    color: $old-color-6;
  }
}

.button-primary {
  @include button-base;
  background-color: $color-1;
  color: $color-2;
  border-radius: 999px;

  &:hover {
    background-color: darken($color-1, 5);
  }
}

.button-primary-inverted {
  @include button-base;
  background-color: $color-2;
  color: $color-1;
  border-radius: 999px;
}

.button-secondary {
  @include button-base;
  background-color: transparent;
  color: $color-3;
  border: 1px solid $color-3;
  border-radius: 999px;

  &:hover {
    background-color: $color-3;
    color: #fff;
  }
}

.button-error {
  @include button-base;
  @apply bg-red-600 text-white border-red-800 rounded-full hover:bg-red-700;
}

.button-m {
  padding: 12px 24px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
}

.button-l {
  padding: 24px 48px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
}

// alert

.alert-danger {
  @include alert-base;
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-warning {
  @include alert-base;
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-success {
  @include alert-base;
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

// inputs

.old-input {
  display: inline-block;
  padding: 12px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid $old-color-5;
  transition: border-color 0.075s ease-in;
  margin: 12px 0;
  background: $old-color-6;

  &:focus {
    border-color: $old-color-1;
  }
}

.input {
  display: inline-block;
  padding: 19px;
  width: 100%;
  border-radius: 999px;
  border: 1px solid $color-5;
  transition: border-color 0.075s ease-in;
  background: $color-2;
}

.active_input {
  display: inline-block;
  padding: 19px;
  width: 100%;
  border-radius: 999px;
  border: 1px solid $color-3;
  transition: border-color 0.075s ease-in;
  background: $color-2;
}

.active_input::placeholder {
  color: $color-3;
}

// links

.old-link {
  border: none;
  padding: 0;
  background: none;
  margin: 0;
  color: $old-color-7;

  &:hover,
  &:focus {
    text-decoration: underline;
    background: none;
  }
}

.link {
  color: $color-6;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: underline;
    background: none;
  }
}

// typography

.print-typography {
  --report-fs: 16px;
  --report-lh: 26px;

  @media print {
    --report-fs: 11px;
    --report-lh: 18px;
  }

  font-size: var(--report-fs);
  line-height: var(--report-lh);

  h1 {
    margin-top: var(--report-lh);
    font-size: calc(1.8 * var(--report-fs));
    line-height: calc(var(--report-lh) * 1.6);
  }

  h2 {
    margin-top: var(--report-lh);
    font-size: calc(1.4 * var(--report-fs));
    line-height: calc(var(--report-lh) * 1.4);
  }

  h3 {
    margin-top: var(--report-lh);
    font-size: calc(1.2 * var(--report-fs));
    line-height: calc(var(--report-lh) * 1.2);
  }

  p,
  ul,
  ol {
    margin-top: calc(var(--report-lh) / 2);
    margin-bottom: calc(var(--report-lh) / 2);
  }

  li > p {
    margin: 0;
  }

  small {
    font-size: calc(0.85 * var(--report-fs));
    line-height: calc(0.8 * var(--report-lh));
  }
}
