.Table {
    &--header-column {
        * td:first-child {
            font-weight: bold;
        }
    }

    th {
        font-weight: bold;
        color: var(--color-9);
        text-align: start;
    }

    td,
    th {
        padding: .25rem .5rem;
        vertical-align: top;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}