@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  --color-neutral: #040a8f;
  --color-positive: #438f04;
  --color-negative: #c72400;
  --color-background: #fff;
  --color-shade: #839c92;
  --color-light: #e6e7e6;
  --color-dark: #202d36;

  margin: 0;
  /* overflow:hidden; */
  /* height: 100vh; */

  @apply h-full;
  @apply bg-gray-100;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer components {
  .dro-link {
    @apply underline hover:decoration-dashed  decoration-1	 underline-offset-char-1/4;
  }

  .report > h1, .dro-h1 {
    @apply text-4xl font-bold !important;
  }

  .report > h2 {
    @apply text-3xl font-bold !important;
  }

  .report > h3 {
    @apply text-2xl font-bold !important;
  }

  .report > h4 {
    @apply text-xl font-bold !important;
  }

  .break-anywhere {
    overflow-wrap: anywhere;
  }
}

